<template>
  <div class="dclist main-cnt">
    <div class="title">餐厅列表</div>
    <div class="content">
      <common-table
        ref="dinnerTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="DinnerApi.diningList"
        :filters="filters"
        :columns="tableColumns"
        @statusChange="statusChange"
        @edit="dinnerEdit"
        @showAlbum="showAlbum"
      >
        <!-- <template #operate>
          <el-button
            type="primary"
            round
            @click="showDiningDialog"
            v-if="authData.indexOf('r_iR7K9x3JFjQ41EYtrDZaXIWmUoOf') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增餐厅</el-button
          >
        </template> -->
      </common-table>
    </div>

    <!-- 新增/编辑 餐厅弹框 -->
    <w-dialog
      ref="diningDialog"
      class="dining-dialog"
      :title="isEdit ? '编辑餐厅' : '新增餐厅'"
      width="55%"
      btnWidth="140px"
      top="15vh"
      :confirmText="isEdit ? '确认编辑' : '确认新增'"
      @wConfirm="dinnerConfirm"
    >
      <el-form
        class="dining-form"
        ref="diningFormObj"
        :model="diningForm"
        :rules="diningRules"
        labelPosition="top"
      >
        <el-form-item label="选择项目" prop="village">
          <el-select
            v-model="diningForm.village"
            clearable
            :disabled="isEdit"
            placeholder="请选择项目"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="餐厅名称" prop="name">
          <el-input
            v-model="diningForm.name"
            placeholder="请输入餐厅名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="diningForm.tel"
            placeholder="请输入餐厅联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="餐厅地址" prop="addr">
          <el-input
            v-model="diningForm.addr"
            placeholder="请输入餐厅地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="餐厅坐标" prop="coordinate">
          <el-input
            v-model="diningForm.coordinate"
            placeholder="请输入餐厅坐标"
          ></el-input>
        </el-form-item>
        <el-form-item label="餐厅标签" prop="tag">
          <el-input
            v-model="diningForm.tag"
            placeholder="请输入餐厅标签"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业时间" prop="transitTime">
          <el-time-picker
            v-model="diningForm.transitTime"
            is-range
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item class="block" label="餐厅简介" prop="intro">
          <el-input
            v-model="diningForm.intro"
            :rows="7"
            type="textarea"
            placeholder="请输入餐厅简介"
          />
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 餐厅图册 弹框 -->
    <w-dialog
      ref="dinnerPhoto"
      class="dinner-photo"
      title="餐厅图册"
      width="55%"
      btnWidth="140px"
      top="10vh"
      :hideFooter="true"
      v-loading.fullscreen.lock="fullLoading"
      @wConfirm="dinnerPhoto.close()"
    >
      <!-- 图片上传部分 -->
      <div class="name">餐厅图片</div>
      <img-upload
        :limit="5"
        :fileList="photoAlbum && photoAlbum.length ? photoAlbum : []"
        @uploadFile="uploadFile"
        @deleteFile="deleteFile"
        suggestText="建议尺寸750*592px，不超过2MB"
      ></img-upload>
      <!-- 上传封面 -->
      <div class="name">上传封面</div>
      <img-upload
        :limit="1"
        :fileList="coverPhoto && coverPhoto.length ? coverPhoto : []"
        @uploadFile="uploadFile($event, true)"
        @deleteFile="deleteFile"
        suggestText="建议尺寸750*258px，不超过2MB"
      ></img-upload>
    </w-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { BasicApi, DinnerApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import * as dayjs from "dayjs";
// 导入图片上传组件
import imgUpload from "@/components/img-upload/img-upload.vue";
import { useStore } from "vuex";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "search",
    name: "keyword",
    value: "",
    placeholder: "输入关键字搜索",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "dr_id",
    label: "餐厅ID",
    color: "--text-color",
    minWidth: 50,
  },
  {
    prop: "project",
    objKey: "p_name",
    type: "obj",
    label: "所属项目",
    color: "--text-third-color",
  },
  {
    prop: "dr_name",
    label: "餐厅名称",
    color: "--text-color",
  },
  {
    prop: "dr_hotline",
    label: "联系电话",
    color: "--text-third-color",
  },
  {
    prop: "dr_address",
    label: "地址信息",
    minWidth: 150,
    color: "--text-color",
  },
  {
    prop: "dr_introduce",
    label: "餐厅简介",
    minWidth: 150,
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    type: "switch",
    prop: "dr_status",
    label: "餐厅状态",
    minWidth: 60,
    token: "r_DtKHXyneTiFNCRU72rQW9O5GMg3V",
  },
  {
    type: "block",
    prop: "files_count",
    label: "图册",
    minWidth: 60,
    token: "r_uG85SPs1TVzOdtf0nUL2EYBgpWm7",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "r_gyTrOo1fPse8awhZX32xuV9iqcvW",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 餐厅表格数据 */
const dinnerTable = ref(null);
/** 当前操作行数据 */
const currentRow = ref(null);
/** 点击 图册 */
const showAlbum = (row) => {
  currentRow.value = row;
  dinnerPhoto.value.show();
  store.dispatch("getQiniuData");
  getDinnerImages();
};
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    dr_id: row.dr_id,
    dr_status: row.dr_status == 1 ? 2 : 1,
  };
  DinnerApi.dinStatusUpdate(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("餐厅状态修改成功！");
      // 重新获取餐厅列表数据
      dinnerTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "餐厅状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 表格编辑 */
const dinnerEdit = (row) => {
  currentRow.value = row;
  isEdit.value = true;
  let times = row.dr_open_time.split("-");
  let st = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[0]);
  let et = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[1]);
  diningForm.village = row.dr_pid; // 项目
  diningForm.name = row.dr_name; // 餐厅名称
  diningForm.tel = row.dr_hotline; // 联系电话
  diningForm.addr = row.dr_address; // 餐厅地址
  diningForm.intro = row.dr_introduce; // 餐厅简介
  diningForm.coordinate = row.dr_coordinate; // 餐厅坐标
  diningForm.tag = row.dr_tag[0]; // 餐厅标签
  diningForm.transitTime = [st, et]; // 营业时间
  diningDialog.value.show();
};

/** 新增/编辑 酒店弹框 */
const diningDialog = ref(null);
/** 酒店编辑/新增操作 */
const isEdit = ref(false);
/** 酒店表单对象 */
const diningFormObj = ref(null);
/** 酒店表单数据对象 */
const diningForm = reactive({
  village: "", // 项目
  name: "", // 餐厅名称
  tel: "", // 联系电话
  addr: "", // 餐厅地址
  intro: "", // 餐厅简介
  coordinate: "", // 餐厅坐标
  tag: "", // 餐厅标签
  transitTime: "", // 营业时间
});
/** 酒店表单规则对象 */
const diningRules = reactive({
  village: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入餐厅名称",
      trigger: "blur",
    },
  ],
  tel: [
    {
      required: true,
      message: "请输入餐厅联系电话",
      trigger: "blur",
    },
  ],
  addr: [
    {
      required: true,
      message: "请输入餐厅地址",
      trigger: "blur",
    },
  ],
  intro: [
    {
      required: true,
      message: "请输入餐厅的详细描述",
      trigger: "blur",
    },
  ],
  coordinate: [
    {
      required: true,
      message: "请输入餐厅坐标",
      trigger: "blur",
    },
  ],
  tag: [
    {
      required: true,
      message: "请输入餐厅标签",
      trigger: "blur",
    },
  ],
  transitTime: [
    {
      required: true,
      message: "请选择营业时间",
      trigger: "change",
    },
  ],
});
// /** 弹出 新增/编辑餐厅 弹框 */
// const showDiningDialog = () => {
//   isEdit.value = false;
//   diningForm.village = ""; // 项目
//   diningForm.name = ""; // 餐厅名称
//   diningForm.tel = ""; // 联系电话
//   diningForm.addr = ""; // 餐厅地址
//   diningForm.intro = ""; // 餐厅简介
//   diningForm.coordinate = ""; // 餐厅坐标
//   diningForm.tag = ""; // 餐厅标签
//   diningForm.transitTime = ""; // 营业时间
//   diningDialog.value.show();
// };
/** 餐厅 新增/编辑 确认 */
const dinnerConfirm = () => {
  diningFormObj.value.validate((valid) => {
    if (valid) {
      diningDialog.value.isLoading = true;
      let stime = dayjs(diningForm.transitTime[0]).format("HH:mm:ss");
      let etime = dayjs(diningForm.transitTime[1]).format("HH:mm:ss");
      let data = {
        dr_pid: diningForm.village,
        dr_name: diningForm.name,
        dr_hotline: diningForm.tel,
        dr_address: diningForm.addr,
        dr_coordinate: diningForm.coordinate,
        dr_tag: diningForm.tag,
        dr_open_time: stime + "-" + etime,
        dr_introduce: diningForm.intro,
      };
      let url = "diningAdd";
      if (isEdit.value) {
        url = "diningUpdate";
        data.dr_id = currentRow.value.dr_id;
      }
      DinnerApi[url](data).then((res) => {
        diningDialog.value.isLoading = false;
        let text = isEdit.value ? "编辑" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`餐厅${text}成功！`);
          diningDialog.value.close();
          // 重新获取餐厅数据
          dinnerTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `餐厅${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 餐厅图片 弹框 */
const dinnerPhoto = ref(null);

/** 是否正在上传图片 */
const fullLoading = ref(false);
/** 多文件上传列表 */
const mulFileObj = ref({
  dr_id: "",
  sft_id: "",
  file_type: "",
  files: [],
  cover_files: [],
});
const photoAlbum = ref([]);
const currentSftId = ref(null);
const coverPhoto = ref([]);
/** 获取图片数据 */
const getDinnerImages = () => {
  store.dispatch("getQiniuData");
  coverPhoto.value = [];
  photoAlbum.value = [];
  DinnerApi.getDinnerPhoto({ dr_id: currentRow.value.dr_id }).then((res) => {
    if (res.Code === 200) {
      if (res.Data) {
        currentSftId.value = res.Data.sft_id;
        res.Data.files &&
          res.Data.files.length &&
          res.Data.files.forEach((el) => {
            if (el.sf_cover == 2) {
              coverPhoto.value.push(el);
            } else {
              photoAlbum.value.push(el);
            }
          });
      }
      dinnerTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "获取餐厅图片数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 文件上传 */
const uploadFile = (data, isCover) => {
  if (authData.value.indexOf("r_jmoZK46Ucl3y5Bbf0dE7qFiALzks") != -1) {
    fullLoading.value = true;
    mulFileObj.value.dr_id = currentRow.value.dr_id;
    mulFileObj.value.sft_id = currentSftId.value;
    mulFileObj.value.file_type = 1;
    if (isCover) {
      mulFileObj.value.cover_files.push(data.key);
    } else {
      mulFileObj.value.files.push(data.key);
    }
    // 去抖上传
    debounce(uploadSubmit, 300);
  } else {
    ElMessage.warning("您没有操作的权限！");
  }
};
/** 定时器 id */
const timer = ref(null);
/** 去抖函数 */
const debounce = (fn, delay) => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(fn, delay);
};
/** 上传提交 */
const uploadSubmit = () => {
  DinnerApi.dinPhotoAdd(mulFileObj.value).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("上传成功！");
      // 获取一次图册数据
      getDinnerImages();
    } else {
      let msg = res.Message ? res.Message : "上传失败！";
      ElMessage.error(msg);
    }
    fullLoading.value = false;
  });
  mulFileObj.value = {
    hrt_id: "",
    sft_id: "",
    file_type: "",
    files: [],
    cover_files: [],
  };
};
/** 文件删除 */
const deleteFile = (data) => {
  if (authData.value.indexOf("r_qSDoQ2C5ux1tYb4GWLOZiewVjEmP") != -1) {
    DinnerApi.dinPhotoDel({ sf_id: data.sf_id }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("图片删除成功！");
        // 获取一次图册数据
        getDinnerImages();
      } else {
        let msg = res.Message ? res.Message : "图片删除失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("您没有删除权限！");
  }
};

/** 项目列表数据 */
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      projectOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};

onMounted(() => {
  dinnerTable.value.tableLoad();
  // 获取项目数据
  getProjectData();
});
</script>

<style lang="scss">
.dclist {
  font-family: "Source Han Sans CN";

  .content {
    padding: 20px;
  }

  .dining-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 40px;
        .dining-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-date-editor {
                width: 100%;
                .el-range-input {
                  background-color: transparent;
                }
              }
            }
          }
          .el-form-item.block {
            width: 100%;
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  .dinner-photo {
    .name {
      color: var(--text-color);
      margin-bottom: 4px;
    }
    .img-upload {
      margin-bottom: 20px;
    }
  }
}
</style>
